
 // MQueries
@mixin respond($checkmedia){
    @if $checkmedia==bigdesktop {
        @media only screen and (max-width: #{map-get($MQueries,desktop-regular)}) {
            @content;
        }     
    }
    @if $checkmedia==desktop {
        @media only screen and (max-width: #{map-get($MQueries,desktop-tiny)}) {
            @content;
        }
    }
    @if $checkmedia==bigtablet {
        @media only screen and (max-width: #{map-get($MQueries,tablet-big)}) {
            @content;
        }
    }
    @if $checkmedia==tablet{
        @media only screen and (max-width: #{map-get($MQueries,tablet-regular)}) {
            @content;
        }
    }
    @else if $checkmedia==mobile {
        @media only screen and (max-width: #{map-get($MQueries,mobile-regular)}) {
            @content;
        }
    }
    @else if $checkmedia==smallmobile {
        @media only screen and (max-width: #{map-get($MQueries,mobile-tiny)}) {
            @content;
        }
    }
}


 // Box Shadows

@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
    @if $inset {
      -webkit-box-shadow: $top $left $blur $spread $color inset;
      -moz-box-shadow: $top $left $blur $spread $color inset;
      box-shadow: $top $left $blur $spread $color inset;
    } @else {
      -webkit-box-shadow: $top $left $blur $spread $color;
      -moz-box-shadow: $top $left $blur $spread $color;
      box-shadow: $top $left $blur $spread $color;
    }
    
  }

 // Buttons

  @mixin button-variant($color, $background, $shadow:false) {
    color: $color!important;
    background-color: $background;
    
    @if $shadow {
        @include box-shadow($shadow...);
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        .open > &.dropdown-toggle {
            color: $color!important;
            background-color: darken($background, 10%);
        }
        &:active,
        &.active,
        .open > &.dropdown-toggle {
            background-image: none;
        }
    }

    & svg{
        fill: $color;
    }
   }
   
   @mixin linkbutton-variant($fontColor) {
        color: $fontColor!important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        .open > &.dropdown-toggle {
            color: #ffffff!important;
            transition: 0.35s 0.15s cubic-bezier(0.37, 1.62, 0.49, 0.9);
        }
        &:active,
        &.active,
        .open > &.dropdown-toggle {
            background-image: none;
        }
        &::before{
            background-color: $fontColor;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            z-index: -1;
            opacity: .2;
            transition: 0.35s;
        }
        &:hover::before,
        &:focus::before,
        &.focus::before,
        &:active::before,
        &.active::before{
            transition: height 0.15s,width 0.35s 0.15s cubic-bezier(0.37, 1.62, 0.49, 0.9),transform 0.35s 0.15s cubic-bezier(0.37, 1.62, 0.49, 0.9),color 0.35s,bottom 0.35s cubic-bezier(0.37, 1.62, 0.49, 0.9);
            opacity: 1;
            bottom: -20px;
            transform: translate(-15px, -10px);
            width: calc(100% + 30px);
            height: calc(100% + 20px);
            border-radius: $base-border-radius;
        }
        & svg{
            fill:$fontColor;
            margin-left: 8px;
            width: 13px;
            height: 13px;
        }
        &:hover svg,
        &:focus svg,
        &:active svg{
            fill:#ffffff;
            transition: fill 0.35s 0.15s cubic-bezier(0.37, 1.62, 0.49, 0.9);
        }
   }

 // Inputs 

   @mixin input-styles($styles, $focus_styles) {
    $types: 'email', 'number', 'radio', 'password', 'search', 'tel',
            'text', 'url', 'color', 'date', 'datetime',
            'datetime-local', 'month', 'time', 'week';

    @each $type in $types {
        input[type="#{$type}"] {
            @extend #{$styles};

            &:focus {
                @extend #{$focus_styles};
            }
        }
    }

    select,
    textarea {
        @extend #{$styles};

        &:focus {
            @extend #{$focus_styles};
        }
    }
}

 // ScrollBars

 @mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size;
      // height: $size;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $foreground-color;
      border-radius: 8px;
      // border: 3px solid $background-color;
      // -webkit-box-shadow: inset 0 0 6px mix($foreground-color, white, 90%);
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color;
    }
  
    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
      scrollbar-width: thin;
      scrollbar-color: $foreground-color $background-color;
    }
  }

  


  // FLUID TEXT
  
  @function strip-unit($value) {
      @return $value / ($value * 0 + 1);
  }

  @mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
      $u1: unit($min-vw);
      $u2: unit($max-vw);
      $u3: unit($min-font-size);
      $u4: unit($max-font-size);

      @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
          & {
              font-size: $min-font-size;

              @media screen and (min-width: $min-vw) {
                  font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
              }

              @media screen and (min-width: $max-vw) {
                  font-size: $max-font-size;
              }
          }
      }
  }