
@import '../base/basic';
@import '../config/variables';

.c-input{
    position: relative;
    width: 100%;
    display: flex;
    padding: 16px 10px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    font-size: fontSize(text);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &__basic{
        border: 1px solid webColor(mainColor);
    }
    &::placeholder{
        color:webColor(cuaternaryColor);
    }
    
   

    &[type="checkbox"]{
           width: 18px;
            min-width: 18px;
            height: 18px;
            min-height: 18px;
            margin-right: 7px;
    }

}
.c-label{
    font-size: fontSize(little);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 4px;
    display: inline-block;
    &__checkbox{
        font-size: fontSize(tiny);
        color: webColor(legals);
        font-weight: 400;
        
    }
}
