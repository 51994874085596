
@import '../config/variables';
//Objects
@import '../objects/buttons';
@import '../objects/inputs';

.filtros-modal-ctn{
    max-width: 90% !important;
    padding-bottom: 2rem !important;
    padding-top: 1rem !important; 
}

#myModal .modal-content{
    max-width: 80%;
}

#myModal2 .modal-content{
    max-width: 80%;
}

/* Modal Styling */
.modal {
    display: none; /* Hide modal by default */
    position: fixed; /* Position the modal */
    z-index: 1; /* Ensure it appears on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scrolling if needed */
    background-color: rgba(0,0,0,0.4); /* Black background with opacity */
}
  
  .modal-content {
    background-color: #fefefe;
    margin: 10% auto; /* Center modal vertically and horizontally */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Set width of modal */
}
  
  /* Close button */
  .close, .close2 {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    right: 2%;
}
  
  .close:hover, .close:focus, .close2:hover, .close2:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.duo-input{
    display: flex;
    width: 49%;
    align-items: center;
    gap: 1.5rem;
}

.info-datos{
    background: #F4F3FB;
    padding: 100px 150px;
    h2{
        color: #061058;
        text-align: center;
        font-family: "Work Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px; 
        margin-bottom: 37px;
    }
    .cards-ctn{
        display: flex;
        flex-direction: column;
        gap:1.5rem;
        .card-info{
            padding: 24px 40px;
            border-radius: 20px;
            background: white;
            box-shadow: 0px 4px 18px 0px rgba(93, 76, 198, 0.10);
            display: flex;
            flex-direction: column;
            gap: 1rem;
            max-width: 1140px;
            margin: 0 auto;
            width: 100%;
            span{
                color: #110456;
                font-family: "Work Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px; /* 177.778% */
                text-transform: uppercase;
            }
            p{
                color: #110456;
                font-family: "Work Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-bottom:0;
            }
        }
    }
}

.info-programas{
    padding: 100px 150px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .logos-prog{
        display: flex;
        gap: 30px;
        justify-content: center;
        align-items: center;
    }
    .card-prog{
        padding: 32px 40px;
        border-radius: 20px;
        background: var(--Blanco, #FFF);
        box-shadow: 0px 4px 18px 0px rgba(93, 76, 198, 0.10);
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 1140px;
        margin: 0 auto;
        width: 100%;
        div{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            span{
                color: #FB73FF;
                text-align: center;
                font-family: "Work Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                max-width: 600px;
                margin: 0 auto;
            }
            h2{
                color: #110456;
                text-align: center;
                font-family: "Work Sans";
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px; /* 125% */
                max-width: 600px;
                margin: 0 auto;
            }
            h3{
                color: #110456;
                text-align: center;
                font-family: "Work Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                max-width: 600px;
                margin: 0 auto;
            }
        }
        p{
            color: #110456;
            font-family: "Work Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            margin-bottom:0;
        }
    }
    h4{
        color: #2648F5;
        text-align: center;
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }
}

.register{
    &__container{
       
        position: relative;
        width: 100%;
        min-height: 800px;
        padding-bottom: 1vh;
        h1{
            color: webColor(secondaryColor);
            font-size: 42px;
            font-style: normal;
            font-weight: 600;
            line-height: 50px;
            strong{
                color: webColor(mainColor);
            }
        }
        h5{
            padding-top: 30px;
           
        }
        p{
            padding-top: 15px;
            padding-bottom: 20px;
        }
        .text_content{
            max-width: 570px;
            padding-top: 120px;
        }
        .img_content{
            position: absolute;
            
            display: flex;
            align-items: baseline;
            justify-content: flex-end;
            bottom: 0;
            right: 0;
            top: 15%;

            &::before{
                position: absolute;
                content: '';
                background-image: url(../../imgs/bg_home.svg);
                background-repeat: no-repeat;
                background-position: right;
                background-size: 750px;
                width: 1000px;
                height: 850px;
                bottom: 0;
                z-index: -1;
                top: -25%;
                right: 0;
            }

            img{
            }

        }
        @include respond(tablet){
                padding: 40px 20px;
        }
        @include respond(mobile){
            max-height:none;
            height: auto;
            
            .text_content{
                width: 100%;
                padding-top: 3rem;
                h1{
                    max-width: 280px;
                     font-size: 32px;
                   
                    line-height: 38px;
                }
                h5{
                    max-width: 320px;
                }
                p{
                    max-width: 320px;
                }
            }
            .img_content{
               display: none;
            }
        }
    }
    &__form{
        max-width: 655px;
        margin-top: 32px;
        &_inputs{
            padding-bottom: 30px;
        }
        &_button{
            &.alert_required{
                position: relative;
                &::before{
                    position: absolute;
                    right: -100%;
                    content: 'Campos requeridos';
                    color:webColor(warning);
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18px;

                }
            }
            
        }
        .already_user{
                display: none;
                &.active{
                    display: block;
                }
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
                color: webColor(warning);
                max-width: 290px;
                margin-left: 30px;
                a{
                    text-decoration: underline;
                    line-height: 18px;
                    font-size: 14px;
                    font-weight: 700;
                    color: webColor(warning);
                }
            
        }
        .already_user2{
                display: none;
                &.active{
                    display: block;
                }
                font-size: 14px;
                line-height: 18px;
                font-weight: 700;
                color: webColor(warning);
                max-width: 290px;
                margin-left: 30px;
                a{
                    text-decoration: underline;
                    line-height: 18px;
                    font-size: 14px;
                    font-weight: 700;
                    color: webColor(warning);
                }
            
        }
        @include respond(mobile){
            margin-top: 0;
        }
        
    }
    &__tologin{
        margin-top: 2rem;
        p{
            font-size: fontSize(tiny);
            font-weight: 400;
            padding-bottom: 0;
            margin-bottom: 100px;
        }
        a{
            color:webColor(link);
        }
        @include respond(mobile){
            margin-top: 1vh;
        }
    }
    
  
}
.inputs{
    &__container{
        margin-top: 25px;
        display: flex;
        flex-direction: column;
    
        &_dual{
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            .inputs__element{
                width: 49%;
            }
            .inputs__element_w25{
                width: 50%;
            }
        }
        &_halfer{
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            .inputs__element{
                width: 23.5%;
                margin-right: 2%;
            }
            .inputs__element_w100{
                width: 49%;
                margin-right: 0;
            }
        }
        &_legal{
            margin-top: 20px;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            &:last-child{
                margin-top: 5px;
               }
        }

        .inputs__element{
            
                &.alert_required{
                    position: relative;
                    input{
                        overflow: visible;
                        border: 1px solid webColor(warning)!important;
                    }
                  
                    &::before{
                        content: '*Requerido';
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: fontSize(tiny);
                        font-weight: 600;
                        color: webColor(warning);
                        line-height: fontSize(tiny) + 3px;

                    }

                }
        }
        input[type="checkbox"]{
            &.alert_required{
                position: relative;
                &::after{
                    position: absolute;
                    content:"";
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    background-color: webColor(warning);
                }
            }
        }
        @include respond(mobile){
           &_legal{
               margin-top: 20px;
               &:last-child{
                margin-top: 5px;
               }
            }
            &_dual{
                flex-direction: column;
                margin: 0;
                .inputs__element{
                    width: 100%;
                    margin-top: 10px;
                }
            }
            &_halfer{
                flex-direction: row;
                margin-top: 20px;
                .inputs__element{
                    
                    width: 48%;
                    margin: auto;
                }
            }
        }
    }
    &__element{
       width: 100%;
    //    margin-top: 24px;
       &_w50{
        width: 49%;
       }
       label{
        
       }
       input{
        
       }
    }
}

@media only screen and (max-width: 500px){
    select#gender {
        /* Apply your desired styles */
        /* For example: */
        -webkit-appearance: none; /* Remove default iOS styling */
        -moz-appearance: none; /* Remove default Firefox styling */
        appearance: none; /* Remove default browser styling */
    }

    .register__tologin p{
        margin-bottom: 0;
    }
    
    .info-datos{
        padding: 40px 30px;
    }

    .info-datos h2{
        line-height: 32px;
    }

    .info-datos .cards-ctn .card-info{
        padding: 20px;
    }

    .info-programas .card-prog{
        padding: 32px 24px;
    }

    .info-programas .card-prog div span{
        text-align: left;
    }

    .info-programas .card-prog div h2{
        text-align: left;
        font-size: 24px;
        line-height: 32px;
    }

    .info-programas .card-prog div h3{
        text-align: left;
    }

    .info-programas h4{
        max-width: 290px;
        margin: 0 auto;
    }

    .info-programas{
        padding: 60px 30px;
    }

    .logos-prog img{
        max-width: 150px;
    }

    .inputs__element_w50{
        width: 100%;
        margin-top: 24px;
    }

    .duo-input{
        width: 100%;
    }

    .inputs__container_dual .inputs__element{
        margin-top: 24px;
    }

    .inputs__container{
        // margin-top: 0;
    }
}