@import '../base/basic';
@import '../config/variables';

%button{
   
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    min-width: 140px;
    min-height: 50px;
   
    padding: 10px 24px;

    font-size: fontSize(par);
    text-align: center;
    white-space: nowrap;

    border: none;
    border-radius: 100px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    letter-spacing: normal !important;
    font-weight: 400;
    
    transform: translate3d(0, 0, 0);
    transition: 0.3s;
    text-decoration: none;
    justify-content: center;
    z-index: zeting(buttons);
    & svg{
        width: fontSize(little);
        height: fontSize(little);
    }   
    &:active{
     box-shadow: 0px 0px 2px 1px #848383 !important;
    }
   
}
%linkbutton{
    position: relative;
    display: inline-block;
    z-index: zeting(buttons);
}

%bigger--button{
    font-size: fontSize(h5);
    min-height: 83px;
    max-height: 83px;
}
.btn--big {
    @extend %bigger--button;
}

.btn_reset{
    @extend %button;
    color:webColor(mainColor)!important;
    border: 1px solid webColor(mainColor)!important;
    background-color: transparent;
    &.small{
        height: 42px;
        font-size: fontSize(text);
    }
    &.tiny{
        height: 30px;
        font-size: fontSize(tiny);
        min-height: auto;
        justify-content: center;
        min-width: auto;
    }
}
.btn_default{
    font-weight: 600;
    @extend %button;
    @include button-variant(webColor(txtSecondaryColor), webColor(secondaryColor), $base-box-shadow);
    &.small{
        height: 42px;
        font-size: fontSize(text);
    }
    &.tiny{
        height: 30px;
        font-size: fontSize(tiny);
         min-height: auto;
         justify-content: center;
        min-width: auto;
    }
}
.btn_disabled{
    pointer-events: none;
    font-weight: 600;
    @extend %button;
    @include button-variant(webColor(txtSecondaryColor), webColor(cuaternaryColor), $base-box-shadow);
    &.small{
        height: 42px;
        font-size: fontSize(text);
    }
    &.tiny{
        height: 30px;
        font-size: fontSize(tiny);
         min-height: auto;
         justify-content: center;
        min-width: auto;
    }
}
.btn_default--arrowright::after{

    content: url('../../imgs/arrow-right.svg');
    width: 40px;
    height: 25px;
}
.btn_warning{
    font-weight: 800;
    @extend %button;
    @include button-variant(webColor(txtSecondaryColor), webColor(warning2), $base-box-shadow);
    
}
.btn_action{
    font-weight: 600;
    @extend %button;
     @include button-variant(webColor(txtSecondaryColor), webColor(mainColor), $base-box-shadow);
   
    color:#ffffff;
    svg{
        fill:#ffffff;
    }
    display: flex;
    align-items: center;
    justify-content: center;
   //  @include button-variant(webColor(txtSecondaryColor), webColor(mainColor), $base-box-shadow);
   &.small{
        height: 42px;
        font-size: fontSize(text);
    }
    &.tiny{
        height: 30px;
        font-size: fontSize(tiny);
         min-height: auto;
         justify-content: center;
          min-width: auto;
    }
}
.btn_action--arrowright::after{

    content: url('../../imgs/arrow-right.svg');
    width: 40px;
    height: 25px;
}
.btn_clean{
    @extend %button;
    @include button-variant(webColor(mainColor),transparent);
}


